import React from 'react';
import PreHeader from '../components/PreHeader'
import Header from '../components/Header'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import CTA from '../components/CTA'

export default () => (
    <div id="app">
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="Description" content="WRAL Digital Solutions Refresh Terms and Conditions Page"></meta>
          <title>Terms & Conditions | WRAL Digital Solutions</title>
          <link rel="canonical" href="https://www.wraldigitalsolutions.com/terms-conditions/" />
        </Helmet>
      <PreHeader/>
      <Header/>
      <h1 className="advertising--title">Terms & Conditions</h1>

      <div className="advertising">
        <h2 className="advertising--subhead terms">WRAL Digital Solutions</h2>
        <h3 className="advertising--subhead terms">Client Terms and Conditions</h3>
        <h5>Last updated: March 29, 2019</h5>
        <p className="advertising_text terms-pad">
        THESE CLIENT TERMS AND CONDITIONS (THIS "<span className="bold">AGREEMENT</span>”) CONSTITUTE A LEGAL AGREEMENT
         BETWEEN YOU (“<span className="bold">CUSTOMER</span>”) AND CBC NEW MEDIA GROUP, LLC AND GOVERN CLIENT’S USE
         AND ACCESS OF THE SERVICES (AS DEFINED BELOW). BY USING THE SERVICES, CLIENT AGREES TO CONTINUE TO BE BOUND
        BY EACH OF THE TERMS AND CONDITIONS SET FORTH HEREIN.
        </p>
        <p className="advertising_text terms-pad">
        CBC New Media Group, LLC d/b/a WRAL Digital Solutions ("<span className="bold">WRAL DIGITAL SOLUTIONS</span>”) provides a range of
        digital marketing services for businesses (collectively, "<span className="bold">Services</span>”). This Agreement relates to all
        services provided by WRAL Digital Solutions under the “WRAL Digital Solutions” brand.  Services may
        be ordered by Client via a sales order or other writing acknowledged by the Client that sets forth:
        (a) which Services are being purchased by Client; (b) the costs for the Services; and (c) other
        relevant details of the proposed marketing campaign (the “<span className="bold">Order</span>”). This Agreement is incorporated
        by reference into and made a part of any Order, including changes to previous Orders, between WRAL
        Digital Solutions and Client and shall govern the relationship between WRAL Digital Solutions and Client.
        </p>
      {/* <h4 className="advertising_text">For proof of our experise check out examples of our past success:</h4>
      <h3 className="advertising--subhead">Case Studies</h3> */}
        <p className="advertising_text terms-pad">
          WRAL Digital Solutions may use third party service providers to support and/or supply some or all of the technology,
          platforms, content and/or other products/services for use in connection with the Services. Some third party service
          providers may require Client to accept additional terms and conditions as a condition to use of their products or services,
          which terms may be identified in the Order or required as a click to accept agreement prior to use
          of the third party product or service.
        </p>
    <div className ="terms-stuff">
      <ol>
      <li><strong>Services.</strong> The Services that may be outlined on an Order and provided by WRAL Digital Solutions as part of a proposed marketing campaign (the “<strong>Campaign</strong>”) are as follows:
      <p> </p>
      <ul>
      <li>.com ecosystem advertising services include advertising on WRAL.com, other WRAL.com branded and/or affiliate websites, the WRAL.com family of mobile apps, and WRAL.com and third party OTT apps and platforms, including targeted display ads, contests and promotions, events and sponsorships, native advertising, video advertising and email advertising.</li>
      <li>Media buying services include the purchase of search engine marketing, social media advertising, programmatic buying, email marketing, and other media buying.</li>
      <li>Website and analytics services include web design, website hosting, SEO (search engine optimization), and site analytics consulting.</li>
      <li>Creative services include social media management and content production, graphics and logo design, article writing/blogs, video/photography, email (targeted blasts, design), and reputation management.</li>
      </ul>
      <p> </p>
      </li>
      <li><strong>Online Advertising and Listing Services.</strong> WRAL Digital Solutions shall determine, in its sole discretion, on which online properties (the <strong>“Publisher”</strong>) the marketing content (<strong>“Ads”</strong>) will run during the course of the Campaign. Client acknowledges that WRAL Digital Solutions does not operate or control the Publisher – with the exception of properties owned or operated by WRAL Digital Solutions Marketing or its affiliates (the <strong>“WRAL Properties”</strong>). WRAL Digital Solutions is not responsible for any Publisher’s website, policies, practices or terms and conditions. WRAL Digital Solutions makes no guarantees about when or where the Ads will be displayed by the Publisher or by WRAL Digital Solutions. WRAL Digital Solutions may create and post online on the WRAL Properties and/or in the directories of third parties, profile page(s) for Client, which may include the following information regarding Client: name, phone number, email address, physical address and information regarding the products or services of Client (the <strong>“Local Profile”</strong>). Client is responsible for ensuring the accuracy of all information in the Local Profile.</li>

      <p> </p>

      <li><strong>Campaign Logistics. </strong>Client acknowledges that WRAL Digital Solutions may require input from Client with respect to the Campaign from time to time and Client agrees to provide timely feedback so that the Services may be delivered in accordance with the agreed upon schedule.<strong>        </strong></li>

      <p> </p>

      <li><strong>Fees.</strong> Client acknowledges and agrees to pay the fees for the Services as set forth on the Order.</li>

      <p>(a) Web Hosting. Standard website hosting includes up to 1GB of storage and 1GB of bandwidth. If a website is in need of additional storage and bandwidth, we reserve the right to add the necessary fees to the website hosting product. Fees for web hosting can range between $50 and $450/per month and are determined upon WRAL Digital Solutions’ consult with Client.</p>
      <p>(b) WRAL Digital Solutions is not responsible for the renewal of client domains. It is the client’s responsibility to stay informed in regards to domain renewal dates and directly pay their domain hosting company any renewal fees. </p>
      <p>Client may opt-in to a monthly maintenance package, which includes one hour of website maintenance by adding $100 to the established hosting fee.</p>
      <p>The grid below outlines the levels of hosting and the range of fees.</p>
      <table width="0">
      <tbody>
      <tr>
      <td width="258">
      <p><strong>Storage and Traffic / Sessions</strong></p>
      </td>
      <td width="156">
      <p><strong>Range</strong></p>
      </td>
      <td width="126">
      <p><strong>Frequency </strong></p>
      </td>
      </tr>
      <tr>
      <td width="258">
      <p>Level One &#8211; Basic</p>
      </td>
      <td width="156">
      <p>$50 &#8211; $75</p>
      </td>
      <td width="126">
      <p>Per Month</p>
      </td>
      </tr>
      <tr>
      <td width="258">
      <p>Level Two &#8211;  Small</p>
      </td>
      <td width="156">
      <p>$100 -$150</p>
      </td>
      <td width="126">
      <p>Per Month</p>
      </td>
      </tr>
      <tr>
      <td width="258">
      <p>Level Three &#8211;  Medium</p>
      </td>
      <td width="156">
      <p>$200 &#8211; $250</p>
      </td>
      <td width="126">
      <p>Per Month</p>
      </td>
      </tr>
      <tr>
      <td width="258">
      <p>Level Four &#8211;  Large</p>
      </td>
      <td width="156">
      <p>$300 &#8211; $350</p>
      </td>
      <td width="126">
      <p>Per Month</p>
      </td>
      </tr>
      </tbody>
      </table>
      <p> </p>

      <li><strong>Payment Terms. </strong>All payments due hereunder are in U.S. dollars and are exclusive of any sales, use or similar applicable taxes, which shall be the sole responsibility of Client. Payment of fees is due before commencement of Services unless Client has established credit terms with WRAL Digital Solutions, in which case payment is due within thirty (30) days of the date of invoice.  In the event Client fails to make timely payments, WRAL Digital Solutions may discontinue, terminate or suspend the Services.</li>

      <p> </p>

      <li><strong>Term; Termination.</strong> The term of this Agreement is set forth on the applicable Order, unless terminated sooner as provided herein. Client may terminate the Campaign during the Campaign Period upon thirty (30) days prior written or email notice to WRAL Digital Solutions in accordance with these terms.  Either party may terminate the Order on thirty (30) days prior written notice if the other party is in material breach of its obligations under the Order and/or this Agreement and such breach has not been cured within such thirty (30) day period. Client acknowledges and agrees that it will be responsible for any obligations accrued through the effective termination date including expenses incurred not billed at time of notice of termination.  All provisions of this Agreement that by their sense or nature should survive termination of the Order (including, without limitation, all limits of liability, indemnity obligations, and confidentiality obligations) shall so survive. Without limiting the generality of the foregoing, in the event of any termination, Client shall remain liable for any amounts due to WRAL Digital Solutions as of the effective date of termination.</li>

      <p> </p>

      <li><strong>Client Data.</strong> From time to time during the Campaign, Client will provide certain information to WRAL Digital Solutions (the <strong>“Client Data”</strong>), which Client hereby authorizes WRAL Digital Solutions to input into one or more third party web-based marketing platforms. WRAL Digital Solutions will only use such information in connection with the fulfillment of the Services and as otherwise permitted by the Order and this Agreement.</li>

      <p> </p>

      <li><strong>Privacy Considerations.</strong> Client shall, at all times, post a privacy policy on its website and comply with such privacy policy. The privacy policy shall comply with all applicable laws and shall not contain any terms that are inconsistent with, or would otherwise restrict WRAL Digital Services from performing its obligations hereunder. In addition, to the extent that Client’s website collects personally identifiable information, Client’s privacy policy must permit the transmission of such information through third party provider sites. If Client is purchasing Services that include targeted display ads, Client is obligated to ensure that its privacy policy permits the targeting and tracking of users contemplated by such service and the transmission of any resultant non- individually identified data gathered about such users to third parties. Client hereby agrees at its expense to fully defend, indemnify and hold harmless WRAL Digital Solutions and its affiliates for any liability, cost or damages incurred by WRAL Digital Solutions or its affiliates due to failure of Client’s privacy policy to comply with the foregoing requirements or with any applicable law.</li>

      <p> </p>

      <li><strong>Services Content; Creative Services; Keywords.</strong>
     <ul>
        <li>Services Content. Client will deliver all content required for any Ad to WRAL Digital Solutions in accordance with the agreed upon schedule.  Client is solely responsible for ensuring that all content is complete, accurate, is non-infringing and complies with applicable law, and is liable for any and all claims and damages relating to such content. Client acknowledges that it may be limited in its ability to make further modifications to its Ads after they have been delivered to WRAL Digital Solutions. Client acknowledges and agrees that WRAL Digital Solutions shall not be liable for typographical errors, incorrect insertions or omissions in the published Ad or in the failure to publish an Ad.</li>
      <li>Creative Services. WRAL Digital Solutions may as part of the Services create or modify an Ad or other content or provide other creative services (the <strong>“Creative Product or Service”</strong>). Client is solely responsible for ensuring that all content in any Creative Product is complete, accurate, is non-infringing and complies with applicable law, and is liable for any and all claims and damages relating to such content. With respect to an Ad or content created by WRAL Digital Solutions, as between Client and WRAL Digital Solutions, WRAL Digital Solutions shall retain ownership of the design elements of the Ad or content, excluding any trade name, trademark, service mark or logo of Client or other pre-existing proprietary elements of Client that may be included within the Ad or content, that predate its creation. WRAL Digital Solutions hereby grants to Client a non-exclusive, royalty-free, worldwide license to use the Creative Product or Service during the term of this Agreement.</li>
      <li>Keywords. With respect to search engine marketing, WRAL Digital Solutions shall have discretion to select the individual words or phrases (<strong>“Keywords”</strong>) to be used in connection with the Campaign. Client may also request the use of certain Keywords. While WRAL Digital Solutions will use reasonable efforts to use these Keywords, WRAL Digital Solutions makes no guarantees that all such Keywords will be used. To the extent that WRAL Digital Solutions uses Keywords of its choosing, it shall be under no obligation to disclose such Keywords to Client. <strong>To the extent that Client elects to use keywords that include the names of its competitors or trademarked terms (“Competitor Keywords”), Client does so at its own risk and acknowledges and agrees that it bears all liability associated with such action, and hereby agrees to fully indemnify WRAL Digital Solutions and its affiliates for any such use. Without limiting the foregoing, Client further acknowledges that WRAL Digital Solutions may, at any time and in its sole discretion, remove Competitor Keywords, but will not have the obligation to do so.</strong></li>
      <p> </p>
     </ul>
     </li>
      <li><strong>Licenses.</strong> During the term of this Agreement, Client hereby grants to WRAL Digital Solutions and the Publisher a non-exclusive, royalty-free, worldwide license to use, copy, modify (as permitted herein), publicly perform, display, broadcast and transmit: (a) any Ad delivered hereunder in accordance with the terms of an Order and this Agreement; and (b) any copyright and/or trademarks, to the extent necessary for WRAL Digital Solutions to perform the services contemplated by the Order and this Agreement. Except as set forth in Subsection 9(b) above, title to and ownership of all intellectual property rights of all Ads and associated Client intellectual property shall remain with Client or its third party licensors and upon termination, WRAL Digital Solutions shall promptly return such property to Client. In addition, Client agrees that WRAL Digital Solutions may, during the term of this Agreement and thereafter, include Client’s name (including any trade name, trademark, service mark and logo) and any Ad provided hereunder in Client’s Local Profile, on WRAL Digital Solutions’ customer list, and in its marketing materials, sales presentations and the WRAL Properties.</li>

      <p> </p>

      <li><strong>Client Representations; Warranties and Covenants.</strong> Client is solely responsible for any liability arising out of or relating to the any Ad, trademark or any content provided by Client hereunder and any material to which users can link through such Ad including without limitation any third party content contained therein (<strong>“Linked Content”</strong>). Client represents, warrants and covenants that the Ads, trademarks, and Linked Content, and any portion thereof, do not and will not: (a) infringe on WRAL Digital Solutions’ or any third party’s copyright, patent, trademark, trade secret, moral right or other proprietary rights or right of publicity or privacy; (b) violate any law, statute, ordinance or regulation, including, without limitation, laws and regulations governing export control, false advertising or unfair competition; (c) be defamatory or libelous; (d) be pornographic or obscene; or (e) contain viruses, trojan horses, worms, time bombs, cancelbots or other similar harmful or deleterious programming routines. Client further represents, warrants and covenants that the product or service that is being (or will be) promoted through the Campaign, including any Ad and Linked Content, is: (x) lawful and (y) not the subject of any ongoing investigation by any local, state or federal regulatory or quasi-regulatory authorities.</li>

      <p> </p>

      <li><strong>Indemnification.</strong> Client will indemnify, defend and hold harmless WRAL Digital Solutions and the Publisher and their respective subsidiaries, affiliates and parent companies and each of their respective directors, officers, agents and employees and each of their successors and assigns from and against any and all claims, liabilities, damages, losses, costs, expenses, fees of any kind (including without limitation reasonable attorneys’ fees and expenses) incurred in connection with any claim, action or proceeding arising from or relating to the violation of any rights of any third party, including intellectual property, privacy, publicity or other proprietary rights by Client or anyone using Client’s account. WRAL Digital Solutions reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by Client. If WRAL Digital Solutions does assume the defense of such a matter, Client will reasonably cooperate with WRAL Digital Solutions in such defense. Client will not enter into any settlement or compromise of any such claim, which settlement or compromise would result in any liability to, or any admission of wrongdoing by, any indemnified person or entity, without WRAL Digital Solutions’ prior written consent.</li>

      <p> </p>
      <li><strong>Agency.</strong> Client represents and warrants that, in the event it is purchasing advertising on behalf of another company, it has been authorized by such company to act as its agent in all respects relating to this Agreement, including, without limitation, the making of any elections or giving of any consents (the <strong>“Authorizing Company”</strong>). Without limiting the generality of the foregoing, Client agrees on behalf of the Authorizing Company, that the Authorizing Company has been made aware of, and agrees to be bound by, this Agreement and as applicable any relevant third party terms. Client and the Authorizing Company shall be jointly and severally liable for fulfillment of Client’s obligations under this Agreement, including all payment obligations. Prior to the purchasing of any advertising on behalf of the Authorizing Company, Client will provide WRAL Digital Solutions with any elections and/or consents, executed by a duly authorized representative of the Authorizing Company, relating to the advertising.</li>

      <p> </p>

      <li><strong>Confidentiality.</strong> Except as may be required by applicable law, Client shall not disclose Confidential Information to any third party (other than its employees and representatives who are made aware of and agree to this restriction) without WRAL Digital Solutions’ prior written consent. Except as otherwise expressly herein permitted, no party may issue a press release concerning the existence or terms of this Agreement without the prior written consent of the other party. <strong>“Confidential Information”</strong> means information about WRAL Digital Solutions’ (or its suppliers’) business, products, technologies (including the Platform), strategies, financial information, operations or activities that is proprietary and confidential, including, without limitation, all business, financial, technical and any other information which, from all the relevant circumstances, should reasonably be assumed to be confidential and proprietary. Confidential Information will not include information that is: (a) in the possession of Client free of any obligation of confidentiality at the time of its disclosure; (b) or becomes publicly known other than by a breach of this provision; (c) received without restriction from a non-party free to disclose it; and/or (d) developed independently by Client without reference to the Confidential Information. In addition, information, whether or not Confidential Information, may be disclosed by Client as may be required by applicable law, rule, regulation, or lawful process, provided that, Client, to the extent permitted by applicable law, rule, regulation, or lawful process, first notifies WRAL Digital Solutions in order to permit WRAL Digital Solutions to seek reasonable protective arrangements.</li>

      <p> </p>

      <li><strong>DISCLAIMER OF WARRANTIES.</strong>
      <ul>
      <li><strong> WRAL DIGITAL SOLUTIONS PROVIDES THE WRAL DIGITAL SOLUTIONS WEBSITE, THE PLATFORM AND ALL SERVICES PERFORMED HEREUNDER ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTY OF ANY KIND AND WITHOUT ANY GUARANTEE OF CONTINUOUS OR UNINTERRUPTED DISPLAY OR DISTRIBUTION OF ANY AD OR OTHER AVERTISING PRODUCTS OR SERVICES. IN THE EVENT OF INTERRUPTION OF DISPLAY OR DISTRIBUTION OF ANY AD OR OTHER AVERTISING PRODUCTS OR SERVICES, WRAL DIGITAL SOLUTIONS’ SOLE OBLIGATION WILL BE TO RESTORE SERVICE AS SOON AS PRACTICABLE. WRAL DIGITAL SOLUTIONS DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE AND IMPLIED WARRANTIES ARISING FROM COURSE OF DEALING OR COURSE OF PERFORMANCE. WRAL DIGITAL SOLUTIONS WILL HAVE NO LIABILITY FOR ANY: (i) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT OR INFORMATION; (ii) CLAIMS RELATING TO BREACH OF INTELLECTUAL PROPERTY LAWS OR DEFAMATION; (iii) PERSONAL INJURY OR PROPERTY DAMAGE RESULTING FROM CLIENT’S ACCESS TO OR USE OF THE SERVICES; (iv) UNAUTHORIZED ACCESS TO OR USE OF WRAL DIGITAL SOLUTIONS’ SERVERS OR OF ANY PERSONAL OR FINANCIAL INFORMATION; (v) INTERRUPTION OF TRANSMISSION TO OR FROM THE SERVICES; (vi) BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED ON OR THROUGH THE SERVICES BY ANY THIRD PARTY; (vii) LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, E-MAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE ON OR THROUGH THE SERVICES; OR (viii) MATTERS BEYOND ITS OR THEIR REASONABLE CONTROL. WRAL DIGITAL SOLUTIONS DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED, OFFERED OR PROVIDED BY A THIRD PARTY ON OR THROUGH THE SERVICES OR ANY LINKED WEBSITE.</strong></li>
      <li><strong> TO THE MAXIMUM EXTENT PERMISSIBLE UNDER APPLICABLE LAW, WRAL DIGITAL  SOLUTIONS AND ITS AFFILIATES EXPRESSLY DISCLAIMS ANY LIABILITY WHATSOEVER FOR ANY DAMAGE, SUITS, CLAIMS, AND/OR CONTROVERSIES THAT HAVE ARISEN OR MAY ARISE, WHETHER KNOWN OR UNKNOWN, AT ANY TIME IN THE PAST OR FUTURE, FROM USE OF ANY THIRD PARTY WEBSITE, CONTENT, SERVICE OR PRODUCT.</strong></li>
      </ul>
      </li>


      <p> </p>

      <li><strong>LIMITATIONS OF LIABILITY.</strong>
      <ul>
      <li><strong> TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, NEITHER PARTY SHALL BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, FOR BREACH OF CONTRACT OR WARRANTY, NEGLIGENCE OR STRICT LIABILITY), OR FOR INTERRUPTED COMMUNICATIONS, LOSS OF USE, LOST BUSINESS, LOST DATA OR LOST PROFITS (EVEN IF SUCH PARTY WAS ADVISED OF THE POSSIBILITY OF ANY OF THE FOREGOING), ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT. THE FOREGOING EXCLUSION OF LIABILITY WILL NOT APPLY TO (I) CLIENT’S INDEMNIFICATION OBLIGATIONS, INCLUDING ANY AMOUNTS PAYABLE IN CONNECTION THEREWITH; (II) TO CLIENT’S CONFIDENTIALITY OBLIGATIONS AND (III) CLIENT’S GROSS NEGLIGENCE OR WILLFUL MISCONDUCT.</strong></li>
      <li><strong> TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES SHALL WRAL DIGITAL SOLUTIONS’ CUMULATIVE, AGGREGATE LIABILITY TO CLIENT OR ANY THIRD PARTY EXCEED THE NET AMOUNTS RECEIVED BY WRAL DIGITAL SOLUTIONS HEREUNDER DURING THE 12-MONTH PERIOD IMMEDIATELY PRIOR TO THE INCIDENT GIVING RISE TO SUCH LIABILITY. IN LIEU OF REFUND, WRAL DIGITAL SOLUTIONS SHALL BE PERMITTED, IN ITS SOLE DISCRETION, TO CAUSE THE PLACEMENT OF “MAKE-GOOD” ADVERTISING, PROVIDED THAT, SUCH “MAKE-GOOD” ADVERTISING IS PROVIDED WITHIN A REASONABLE PERIOD OF TIME AFTER THE LIABILITY HAS ACCRUED.</strong></li>
      <li> Prohibition on Certain Exclusions. Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations and disclaimers may not apply to Client. To the extent WRAL Digital Solutions may not, as a matter of applicable law, disclaim any implied warranty or limit its liabilities, the scope and duration of such warranty and the extent of WRAL Digital Solutions’ liability will be the minimum permitted under such law.</li>
      <li> Timing of Claims. Client agrees that, regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to this Agreement must be filed within one year after such claim or cause of action arose or be forever barred, provided that, this section shall not in any way limit the time in which claims for infringement or misappropriation of intellectual property rights may be brought.</li>
      <li>Acknowledgement. Each party acknowledges that the other party has entered into this Agreement in reliance upon the limitations of liability set forth herein and that the same is an essential basis of the bargain between the parties.</li>
      </ul>
      </li>
  <p> </p>
      <li><strong>Third Party Beneficiaries.</strong></li>

      <p>Client acknowledges and agrees that the Publisher is an intended third party beneficiary of Sections 9, 10, 11, 12 and 16.</p>
      <p> </p>

      <li><strong>Miscellaneous.</strong>
      <ul>
      <li>Governing Law/Venue. This Agreement will be governed and construed in accordance with the laws of the State of North Carolina without giving effect to conflict of law rules or principles. In the event that a dispute arises with respect to the terms of this Agreement, Client agrees that the exclusive and sole venue for the resolution shall be a court of competent jurisdiction in North Carolina and further agrees to submit to the jurisdiction of the same.</li>
      <li> Entire Agreement/Amendment. This Agreement (which includes the Order and any payment authorization forms) sets forth the entire agreement of the parties and supersedes any and all prior oral or written agreements or understandings between the parties as to the subject matter hereof.</li>
      <li> Notices. Any written notices to WRAL Digital Solutions required under this Agreement shall be provided by registered mail with proof of delivery or by overnight courier, signature required, to WRAL Digital Solutions’ then current corporate headquarters address (as shown on www.wraldigitalsolutions.com), Attn: General Counsel with a copy sent by email to notices@wral.com. Notices shall be deemed delivered upon actual receipt of hard copy as evidenced by signature proof of delivery.</li>
      <li> Severability. If any provision of this Agreement is held to be invalid or unenforceable for any reason, the remaining provisions will continue in full force without being impaired or invalidated in any way.</li>
      <li> Assignment. Client may not assign this Agreement without the prior written consent of WRAL Digital Solutions. WRAL Digital Solutions may assign this Agreement in whole or in part to any affiliate or to a party that acquires all or substantially all of the assets or business to which this Agreement relates. The parties’ rights and obligations will bind and inure to the benefit of their respective successors, heirs, executors and joint administrators and permitted assigns.</li>
      <li> Independent Contractors. The parties to this Agreement are independent contractors, and no agency, partnership, joint venture or employee-employer relationship is intended or created by this Agreement.</li>
      <li> Force Majeure. Neither party shall have any liability for any failure or delay (other than with respect to payment obligations) resulting from any governmental action, fire, flood, insurrection, earthquake, power failure, riot, explosion, embargo, strikes whether legal or illegal, labor or material shortage, transportation interruption of any kind, work slowdown or any other condition affecting production or delivery in any manner beyond the reasonable control of such party.</li>
      </ul>
      </li>
      </ol>
    </div>

        <div className="creative_banner">
            <h3 className="creative_banner-text">
              To get started today <a href="tel:+1-919-744-3030">call</a> or fill out our contact form.
            </h3>
          <div className="creative_cta">
              <CTA text="Contact Us" type="button" link="/contact" classname="contact-us--mobile"/>
              <CTA text="Contact Us" type="button" link="/contact" classname="contact-us--desktop"/>
          </div>
        </div>

      </div>
      <Footer/>
    </div>
  );
